
<template>
  <config-code apiname="color" idname="colorId" labelname="รหัสสี"></config-code>
</template>

<script>
import ConfigCode from "./ConfigCode.vue";
export default {
  components: {
    ConfigCode
  }
};
</script>

